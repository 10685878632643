import React from "react"
import Layout from "components/Layout"

const NotFoundPage = () => (
  <Layout>
    <h1 className="decoXXL">NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
